import React, { Component, Fragment } from 'react';
import { Card } from './Card';
import { CardSkeleton } from './CardSkeleton';
import { AgreementModal } from './AgreementModal';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Container } from 'reactstrap';
import queryString from 'query-string';
import classnames from 'classnames';
import { UncontrolledCarousel } from 'reactstrap';
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import InstallationsService from '.././services/installationsService';
import SimulationsService from '.././services/simulationsService';
import AgreementsService from '.././services/agreementsService';
import AddonsService from '.././services/addonsService';
import normalizeForSearch from '.././functions/normalizeForSearch';

export class Home extends Component {
    constructor(context) {
        super();

        this.installationsService = new InstallationsService();
        this.simulationsService = new SimulationsService();
        this.agreementsService = new AgreementsService();
        this.addonsService = new AddonsService();

        this.state = {
            addons: [],
            addonsFiltered: [],
            spotlight: [],
            acquiredAddonsFiltered: [],
            allAddonsFiltered: [],
            activeTab: "1",
            consentId: "",
            simulationId: "",
            open: false,
            agreementTile: "",
            agreementText: "",
            installationId: "",
            urlParameters: "",
            categories: ["Todas"],
            addonsCategories: ["Todas"],
            acquiredCategories: ["Todas"],
            allCategories: ["Todas"],
            loading: true,
            isRecommendation: false
        };
        this.setData = this.setData.bind(this);
        this.setAddonsData = this.setAddonsData.bind(this);
        this.setAgreementData = this.setAgreementData.bind(this);
        this.setAcquiredData = this.setAcquiredData.bind(this);
        this.setAllAddonsData = this.setAllAddonsData.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleAgree = this.handleAgree.bind(true);
        this.onAgree = this.onAgree.bind(this);
        this.onDropdownSelected = this.onDropdownSelected.bind(this);
        this.filterByCategory = this.filterByCategory.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setUrlParameters = this.setUrlParameters.bind(this);
    }

    componentDidMount() {
        let parsed = queryString.parse(this.props.location.search.toLowerCase());
        let consentId = parsed.consentid;
        let simulationId = parsed.simulationid;

        if (typeof consentId === 'undefined' && typeof simulationId === 'undefined') {
            this.showDefaultHome("");
            return;
        }

        if (consentId) {
            this.setState({ consentId: consentId });
            this.showConsentHome(consentId);
        }

        if (simulationId) {
            this.setState({ simulationId: simulationId });
            this.showSimulationHome(simulationId);
        }
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {

            switch (tab) {
                case "1":
                    this.setState({ categories: this.state.addonsCategories });
                    break;
                case "2":
                    this.setState({ categories: this.state.acquiredCategories });
                    break;
                default:
                    this.setState({ categories: this.state.allCategories });
                    break;
            }

            this.setState({ activeTab: tab });

            let selectCategories = document.getElementById("selectCategories");
            selectCategories.value = "Todas";
            document.getElementById("txtSearch").value = "";
            this.filterByCategory(selectCategories.value);
        }
    }

    async showDefaultHome(consentId) {
        this.fetchAddonsData();
        var agreement = await this.agreementsService.getLast();
        this.setAgreementData(agreement);
        this.setState({ consentId: consentId, activeTab: "1", categories: this.state.allCategories, loading: false });
    }

    async showConsentHome(consentId) {
        if (!consentId) {
            return;
        }
        let installation = await this.installationsService.getInstallation(consentId);
        if (typeof installation === 'undefined') {
            this.showDefaultHome(consentId);
            return;
        }
        this.setData(installation);
    }

    async showSimulationHome(simulationId) {
        if (!simulationId) {
            this.setState({ loading: false });
            return;
        }
        let simulations = await this.simulationsService.getSegmentedAddons(simulationId);
        if (typeof simulations === 'undefined') {
            this.setState({ loading: false });
            return;
        }
        this.setDataSimulations(simulations);
    }

    async setData(data) {
        if (data) {
            this.setUrlParameters(data);

            if (data.AgreementSigned) {
                this.fetchAddonsData(this.state.consentId);
            } else {

                this.setState({ installationId: data.InstallationId });

                var agreement = await this.agreementsService.getLast(this.state.consentId);
                this.setAgreementData(agreement);
            }
        }
    }

    setDataSimulations(data) {
        if (data) {
            this.setSimulationAddonsData(data.AddOns);
        }
    }

    setUrlParameters(data) {
        var parameters = 'cod=';
        var customerCode = 'NoCustomer';
        var partnerCode = 'NoPartner';
        var installationId = 'NoInstallation';

        if (data.InstallationId !== undefined) {
            installationId = data.InstallationId;
        }

        if (data.InfoRequired) {
            var infoRequired = data.InfoRequired;

            if (infoRequired.SegmentationData) {
                var segmentationData = infoRequired.SegmentationData;

                if (segmentationData.CustomerCode !== undefined) {
                    customerCode = segmentationData.CustomerCode;
                }

                if (segmentationData.PartnerCode !== undefined) {
                    partnerCode = segmentationData.PartnerCode;
                }
            }
        }

        parameters += customerCode + "_" + partnerCode + "_" + installationId;

        this.setState({ urlParameters: parameters });
    }

    setAgreementData(data) {
        if (data) {
            this.setState({ agreementTile: data.Title, agreementText: data.Text, open: true });
        }
        else {
            this.fetchAddonsData();
            this.setState({ loading: false });
        }
    }

    async fetchAddonsData(consentId) {        
        if (consentId) {
            var acquired = await this.addonsService.getAcquired(this.state.consentId);
            this.setAcquiredData(acquired);

            var addons = await this.addonsService.getSegmented(this.state.consentId);
            this.setAddonsData(addons, acquired);

            return;
        }

        var defaultAddons = await this.addonsService.getDefault();
        this.setAllAddonsData(defaultAddons);
    }

    async setSimulationAddonsData(addons) {
        if (addons) {
            this.setAddonsData(addons);

            return;
        }
    }

    getLandingUrl = (url, codActive) => {
        var parameters = this.state.urlParameters;

        if (this.state.consentId !== undefined && this.state.consentId !== '')
        {
            if (parameters !== undefined && parameters !== '' && codActive) {
                if (this.state.isRecommendation) {
                    parameters += "_R"
                }
                else {
                    parameters += "_G"
                }
                url += ((url.includes("?")) ? "&" : "?") + parameters;
            }
        }

        return url;
    };

    setAddonsData(addons, acquired) {
        if (addons) {
            let spotlight = addons.filter(function (addon) {
                return addon.IsSpotlight;
            });

            if (spotlight && spotlight.length > 0) {
                spotlight = spotlight.map(spot => ({
                    src: spot.ImageSpotlightPath,
                    altText: spot.Name,
                    header: spot.Name,
                    caption: <div dangerouslySetInnerHTML={{
                        __html: decodeURIComponent("<a href=\"" + this.getLandingUrl(spot.LandingUrl, spot.CodActive) + "\"> M&aacute;s informaci&oacute;n</a>")
                    }} />
                }));
            }
            else {
                spotlight = null;
            }

            let groupedByCategory = groupBy(addons, 'Category', function (addon) {
                return addon.Category;
            });

            let categories = map(groupedByCategory, function (value, key) {
                return key;
            });

            categories.unshift('Todas');

            this.setState({
                addonsCategories: categories,
                categories: categories,
                addons: addons,
                addonsFiltered: addons,
                spotlight: spotlight,
                activeTab: (addons.length > 0
                    || (acquired && acquired.length === 0) ? "1" : "2"),
                loading: false
            });
        }
    }

    setAcquiredData(addons) {
        if (addons) {
            this.setState({ acquiredAddons: addons, acquiredAddonsFiltered: addons });

            let groupedByCategory = groupBy(addons, 'Category', function (addon) {
                return addon.Category;
            });

            let categories = map(groupedByCategory, function (value, key) {
                return key;
            });

            categories.unshift('Todas');
            this.setState({ acquiredCategories: categories });
        }
    }

    setAllAddonsData(addons) {
        if (addons) {
            this.setState({ allAddons: addons, allAddonsFiltered: addons });

            let groupedByCategory = groupBy(addons, 'Category', function (addon) {
                return addon.Category;
            });

            let categories = map(groupedByCategory, function (value, key) {
                return key;
            });

            categories.unshift('Todas');
            this.setState({ allCategories: categories });
        }
    }

    handleAgree = async () => {
        if (this.state.consentId === '') {
            this.setState({ open: false });
            return;
        }

        var accepted = await this.installationsService.acceptAgreement(this.state.consentId);
        if (accepted) {
            this.onAgree();
        }
        else {
            this.fetchAddonsData();
            this.setState({ consentId:'', open: false });
        }
    }

    onAgree = () => {
        this.fetchAddonsData(this.state.consentId);
        this.setState({ open: false });
    }

    onDropdownSelected(e) {
        this.filterByCategory(e.target.value);
    }

    filterByCategory(category, keepSearchFilter) {
        if (!keepSearchFilter) {
            document.getElementById("txtSearch").value = "";
        }

        let activeTab = this.state.activeTab;
        let addonsProp = "";
        let filteredAddonsProp = "";
        switch (activeTab) {
            case "1":
                addonsProp = "addons";
                filteredAddonsProp = "addonsFiltered";
                break;
            case "2":
                addonsProp = "acquiredAddons";
                filteredAddonsProp = "acquiredAddonsFiltered";
                break;
            default:
                addonsProp = "allAddons";
                filteredAddonsProp = "allAddonsFiltered";
                break;
        }

        if (category !== "Todas") {

            let addons = this.state[addonsProp];

            addons = addons.filter(function (addon) {
                return addon.Category === category;
            });

            this.setState({ [filteredAddonsProp]: addons });
        } else {
            this.setState({ addonsFiltered: this.state.addons, acquiredAddonsFiltered: this.state.acquiredAddons, allAddonsFiltered: this.state.allAddons });
        }
    }

    handleChange(e) {

        let selectCategories = document.getElementById("selectCategories");
        let category = selectCategories.options[selectCategories.selectedIndex].value;
        this.filterByCategory(category, true);

        let self = this;
        let activeTab = this.state.activeTab;
        let filteredAddonsProp = "";
        switch (activeTab) {
            case "1":
                filteredAddonsProp = "addonsFiltered";
                break;
            case "2":
                filteredAddonsProp = "acquiredAddonsFiltered";
                break;
            default:
                filteredAddonsProp = "allAddonsFiltered";
                break;
        }
        if (e.target.value && e.target.value !== "") {
            let search = normalizeForSearch(e.target.value);
            let addons = this.state[filteredAddonsProp];

            addons = addons.filter(function (addon) {
                return normalizeForSearch(addon.Name).indexOf(search) >= 0 ||
                    normalizeForSearch(self.getInnerText(addon.Description)).indexOf(search) >= 0;
            });

            this.setState({ [filteredAddonsProp]: addons });
        }
    }

    getInnerText(text) {
        text = decodeURIComponent(text).replace(/<[^>]*>?/gm, '');
        return text;
    }

    render() {
        return (
            <Fragment>
                {this.state.spotlight && this.state.spotlight.length > 0 && (
                    <div className="row div-carousel">
                        <UncontrolledCarousel items={this.state.spotlight} interval='3000' />
                    </div>
                )}
                <AgreementModal
                    title={this.state.agreementTile}
                    text={this.state.agreementText}
                    open={this.state.open}
                    onAgree={this.handleAgree}>
                </AgreementModal>
                <div className="row">
                    <div className="col-sm form-inline">
                        <label className="mr-3">Categor&iacute;as:&nbsp;</label>
                        <select id="selectCategories" onChange={this.onDropdownSelected} label="Seleccionar categoría" disabled={this.state.loading} className="form-control categories-select">
                            {this.state.categories.map((category, i) => {
                                return (<option key={category} value={category}>{category}</option>);
                            })}
                        </select>
                    </div>
                    <div className="col-sm">
                        <div className="float-right">
                            <input id="txtSearch" type="text" placeholder="Buscar . . ." onChange={this.handleChange} disabled={this.state.loading} className="form-control" />
                        </div>
                    </div>
                </div>
                {this.state.consentId && (
                    <Fragment>
                        <Nav tabs>
                            <Container>
                                <Row>
                                    <Col>
                                        <NavItem>
                                            <NavLink
                                                disabled={this.state.loading}
                                                className={classnames({ active: this.state.activeTab === "1" })}
                                                onClick={() => { this.toggle("1"); }}
                                            >
                                                M&oacute;dulos disponibles
                                            </NavLink>
                                        </NavItem>
                                    </Col>
                                    <Col>
                                        <NavItem>
                                            <NavLink
                                                disabled={this.state.loading}
                                                className={classnames({ active: this.state.activeTab === "2" })}
                                                onClick={() => { this.toggle("2"); }}
                                            >
                                                Mis m&oacute;dulos
                                            </NavLink>
                                        </NavItem>
                                    </Col>
                                    <Col></Col>
                                </Row>
                            </Container>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <div className="card-columns">
                                        {this.state.loading && (
                                            <Fragment>
                                                <CardSkeleton />
                                                <CardSkeleton />
                                                <CardSkeleton />
                                            </Fragment>
                                        )}
                                        {this.state.addonsFiltered.map((addon, i) => {
                                            return (<Card
                                                isPromoted={addon.IsPromoted}
                                                imagePath={addon.ImagePath}
                                                name={addon.Name}
                                                isNew={addon.IsNew}
                                                category={addon.Category}
                                                description={addon.Description}
                                                url={addon.LandingUrl}
                                                codActive={addon.CodActive}
                                                isRecommendation={this.state.isRecommendation}
                                                urlParameters={this.state.urlParameters}
                                            >
                                            </Card>);
                                        })}
                                    </div>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <div className="card-columns">
                                        {this.state.loading && (
                                            <Fragment>
                                                <CardSkeleton />
                                                <CardSkeleton />
                                                <CardSkeleton />
                                            </Fragment>
                                        )}
                                        {this.state.acquiredAddonsFiltered.map((addon, i) => {
                                            return (<Card
                                                isPromoted={false}
                                                imagePath={addon.ImagePath}
                                                name={addon.Name}
                                                isNew={false}
                                                category={addon.Category}
                                                description={addon.Description}
                                                url={addon.LandingUrl}
                                                urlParameters={this.state.urlParameters}
                                            >
                                            </Card>);
                                        })}
                                    </div>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Fragment>
                )}
                {this.state.simulationId && (
                    <Fragment>
                        <Nav tabs>
                            <Container>
                                <Row>
                                    <Col>
                                        <NavItem>
                                            <NavLink
                                                disabled={this.state.loading}
                                                className={classnames({ active: this.state.activeTab === "1" })}
                                                onClick={() => { this.toggle("1"); }}
                                            >
                                                M&oacute;dulos disponibles
                                            </NavLink>
                                        </NavItem>
                                    </Col>
                                    <Col></Col>
                                    <Col></Col>
                                </Row>
                            </Container>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <div className="card-columns">
                                        {this.state.loading && (
                                            <Fragment>
                                                <CardSkeleton />
                                                <CardSkeleton />
                                                <CardSkeleton />
                                            </Fragment>
                                        )}
                                        {this.state.addonsFiltered.map((addon, i) => {
                                            return (<Card
                                                isPromoted={addon.IsPromoted}
                                                imagePath={addon.ImagePath}
                                                name={addon.Name}
                                                isNew={addon.IsNew}
                                                category={addon.Category}
                                                description={addon.Description}
                                                url={addon.LandingUrl}
                                                codActive={addon.CodActive}
                                                isRecommendation={this.state.isRecommendation}
                                                urlParameters={this.state.urlParameters}
                                            >
                                            </Card>);
                                        })}
                                    </div>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Fragment>
                )}
                {(this.state.consentId === '' && this.state.simulationId === '') && (
                    <Fragment>
                        <Nav tabs>
                            <Container>
                                <Row>
                                    <Col>
                                        <NavItem>
                                            <NavLink
                                                disabled={this.state.loading}
                                                className={classnames({ active: this.state.activeTab === "1" })}
                                                onClick={() => { this.toggle("1"); }}
                                            >
                                                M&oacute;dulos disponibles
                                            </NavLink>
                                        </NavItem>
                                    </Col>
                                    <Col></Col>
                                    <Col></Col>
                                </Row>
                            </Container>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <div className="card-columns">
                                        {this.state.loading && (
                                            <Fragment>
                                                <CardSkeleton />
                                                <CardSkeleton />
                                                <CardSkeleton />
                                            </Fragment>
                                        )}
                                        {this.state.allAddonsFiltered.map((addon, i) => {
                                            return (<Card
                                                isPromoted={addon.IsPromoted}
                                                imagePath={addon.ImagePath}
                                                name={addon.Name}
                                                isNew={addon.IsNew}
                                                category={addon.Category}
                                                description={addon.Description}
                                                url={addon.LandingUrl}
                                            >
                                            </Card>);
                                        })}
                                    </div>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}
