import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Recommendation } from './components/Recommendation';
//import SwaggerUI from "swagger-ui-react";
//import "swagger-ui-react/swagger-ui.css";
import './App.css';

export default class App extends Component {
    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route exact path='/recommendation' component={Recommendation} />
                {/*<Route path="/swagger" render={() => <SwaggerUI url="/swagger/v1/swagger.json" docExpansion="list" />} />*/}
            </Layout>
        );
    }
}
